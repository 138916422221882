<template>
  <v-sheet
    class="elevation-3 d-flex align-center"
    :height="$vuetify.breakpoint.mobile ? 70 : 100"
  >
    <v-container>
      <v-row
        :class="$vuetify.breakpoint.mobile ? 'px-5 pr-8' : ''"
        align="center"
        justify="space-between"
      >
        <v-col
          class="pa-0"
          cols="2"
          md="1"
        >
          <v-img
            src="@/assets/logo.png"
            :max-width="$vuetify.breakpoint.xl ? '50%' : '80%'"
          />
        </v-col>
        <v-col
          v-if="!$vuetify.breakpoint.mobile"
          class="pa-0"
          cols="5"
        >
          <ul class="d-flex align-center">
            <li
              class="mx-3 text-lg-body-2 text-xl-h6"
              v-for="(item, idx) in items"
              :key="idx"
            >
              <v-menu
                v-if="idx == 1"
                bottom
                offset-y
                close-on-click
                min-width="200"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <a
                    v-on="on"
                    v-bind="attrs"
                    :class="`${
                      $route.path == item.route
                        ? 'primary--text'
                        : 'secondary--text'
                    }`"
                  >
                    {{ item.title }}
                    <span><v-icon x-small>fas fa-chevron-down</v-icon></span>
                  </a>
                </template>
                <v-card>
                  <v-treeview
                    :items="allCategories"
                    open-on-click
                    item-key="name"
                    hoverable
                    item-children="categories_all"
                    dense
                  >
                    <template v-slot:label="{ item }">
                      <v-menu
                        v-if="item.type === 1 && item.subcategories.length > 0"
                        open-on-hover
                        offset-x
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-list-item
                            dense
                            v-on="on"
                            v-bind="attrs"
                          >
                            {{ item.name }}
                            <v-icon
                              small
                              right
                            >
                              fas fa-chevron-right
                            </v-icon>
                          </v-list-item>
                        </template>
                        <v-card>
                          <v-list>
                            <v-list-item
                              dense
                              v-for="(item, idx) in item.subcategories"
                              :key="idx"
                              @click="setProductsCategorySearch(item)"
                            >
                              {{ item.name }}
                            </v-list-item>
                          </v-list>
                        </v-card>
                      </v-menu>
                      <v-list-item
                        dense
                        @click="$router.push('/products')"
                        v-else-if="item.id === 'allProducts'"
                      >
                        {{ item.name }}
                      </v-list-item>
                      <v-list-item
                        dense
                        v-else-if="
                          typeof item.id === 'string' &&
                          item.id !== 'allProducts'
                        "
                      >
                        {{ item.name }}
                      </v-list-item>
                      <v-list-item
                        dense
                        @click="setProductsCategorySearch(item)"
                        v-else
                      >
                        {{ item.name }}
                      </v-list-item>
                    </template>
                  </v-treeview>
                </v-card>
              </v-menu>

              <router-link
                v-else
                :class="`${
                  $route.path == item.route
                    ? 'primary--text'
                    : 'secondary--text'
                }`"
                :to="item.route"
              >
                {{ item.title }}
              </router-link>
            </li>
          </ul>
        </v-col>

        <v-navigation-drawer
          v-else
          v-model="$store.state.siteMenuDrawer"
          absolute
          temporary
          class="pt-3"
        >
          <v-list dense>
            <v-list-item
              link
              to="/"
              :class="`${
                $route.path == '/' ? 'primary--text' : 'secondary--text'
              }`"
            >
              <v-list-item-content>
                <v-list-item-title>Início</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-group>
              <template v-slot:activator>
                <v-list-item-title>Produtos</v-list-item-title>
              </template>
              <v-list-item to="/products">
                <v-list-item-title>Todos os produtos</v-list-item-title>
              </v-list-item>
              <v-list-group
                sub-group
                no-action
              >
                <template v-slot:activator>
                  <v-list-item-title>Por Categoria</v-list-item-title>
                </template>
                <v-list-item
                  v-for="(content, idx) in mainCategories"
                  :key="idx"
                  link
                >
                  <v-list-item-title
                    class="pl-3 secondary--text text--lighten-1"
                  >
                    {{ content }}
                  </v-list-item-title>
                </v-list-item>
              </v-list-group>
              <v-list-group
                sub-group
                no-action
              >
                <template v-slot:activator>
                  <v-list-item-title>Por Segmentos</v-list-item-title>
                </template>
                <v-list-item
                  v-for="(content, idx) in segments"
                  :key="idx"
                  link
                >
                  <v-list-item-title
                    class="pl-3 secondary--text text--lighten-1"
                    >{{ content }}</v-list-item-title
                  >
                </v-list-item>
              </v-list-group>
              <v-list-group
                sub-group
                no-action
              >
                <template v-slot:activator>
                  <v-list-item-title>Por Datas Especiais</v-list-item-title>
                </template>
                <v-list-item
                  v-for="(content, idx) in specialDates"
                  :key="idx"
                  link
                >
                  <v-list-item-title
                    class="pl-3 secondary--text text--lighten-1"
                    >{{ content }}</v-list-item-title
                  >
                </v-list-item>
              </v-list-group>
            </v-list-group>
            <v-list-item
              v-for="item in items.filter(
                (i) => i.route != '/' && i.route != '/products'
              )"
              :key="item.title"
              link
              :to="item.route"
              :class="`${
                $route.path == item.route ? 'primary--text' : 'secondary--text'
              }`"
            >
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>

        <v-col
          v-if="!$vuetify.breakpoint.mobile"
          cols="5"
          class="pa-0 d-flex align-center"
        >
          <v-text-field
            placeholder="Buscar produtos"
            append-icon="fas fa-search"
            hide-details
            dense
            outlined
            rounded
          />
          <span
            v-if="is_logged == false"
            class="ml-2"
          >
            <v-btn
              @click="updateDialog"
              icon
            >
              <v-icon color="primary">fas fa-user-circle</v-icon>
            </v-btn>
          </span>
          <span
            v-else
            class="ml-2"
          >
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  v-on="on"
                  v-bind="attrs"
                  rounded
                  outlined
                  color="primary"
                >
                  Olá, {{ $cookies.get('name') }}
                </v-chip>
              </template>
              <v-list dense>
                <v-list-item @click="goToAdmin"> Meu perfil </v-list-item>
                <v-list-item @click="logout"> Sair </v-list-item>
              </v-list>
            </v-menu>
          </span>
        </v-col>
        <v-col
          align="end"
          class="pa-0"
          cols="1"
        >
          <v-badge
            color="accent"
            :content="countProducts"
            overlap
            bordered
          >
            <v-btn
              @click="$router.push('/budget/cart')"
              icon
            >
              <v-icon color="primary">fas fa-shopping-cart</v-icon>
            </v-btn>
          </v-badge>
        </v-col>
      </v-row>
    </v-container>

    <div class="text-center">
      <v-dialog
        v-model="dialog"
        :max-width="$vuetify.breakpoint.xl ? '20%' : '30%'"
      >
        <LoginModal />
      </v-dialog>
    </div>
  </v-sheet>
</template>

<script>
export default {
  components: {
    LoginModal: () => import('./widgets/LoginModal'),
  },
  data: () => ({
    dialog: false,
    is_logged: false,
    valid: false,
    countProducts: '0',
    items: [
      { title: 'Início', route: '/' },
      { title: 'Produtos', route: '/products' },
      { title: 'Gravações', route: '/print' },
      { title: 'Sobre nós', route: '/about' },
      { title: 'Contato', route: '/contact' },
    ],
    allCategories: [
      { id: 'allProducts', name: 'Todos os produtos', categories_all: [] },
      { id: 'categories', name: 'Por Categoria', categories_all: [] },
      { id: 'segments', name: 'Por Segmento', categories_all: [] },
      { id: 'special_dates', name: 'Por Data especial', categories_all: [] },
    ],
    categories: {
      items: [],
      loading: true,
    },
    segments: {
      items: [],
      loading: true,
    },
    specialDates: {
      items: [],
      loading: true,
    },
  }),
  methods: {
    logout() {
      this.$session.destroy()
      this.$cookies.remove('token')
      this.$cookies.remove('isAuth')
      this.$cookies.remove('name')
      this.$cookies.remove('profile')
      this.$cookies.remove('type')
      this.$cookies.remove('id')
      this.isLogged = false

      this.$router.push('/')
      location.reload()
    },
    goToAdmin() {
      this.$router.push('/profile')
    },
    reset() {
      this.$refs.form.reset()
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },
    goTo(route) {
      this.$store.commit('setProductsSearch', { type: null })
      this.$router.push(route)
    },
    setProductsCategorySearch(item) {
      // this.$store.commit('setProductsSearch', {
      //   type: type,
      //   category_name: item,
      // })
      this.$router.push({
        path: `/products_by_category/${item.name}`,
      })

      window.location.reload()
    },
    getCategories() {
      this.$api
        .get('categories_site/mains')
        .then((res) => {
          this.allCategories[1].categories_all = res.data.data
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.categories.loading = false
        })
    },
    getSegments() {
      this.$api
        .get('categories_site/segments')
        .then((res) => {
          this.allCategories[2].categories_all = res.data.data
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.segments.loading = false
        })
    },
    updateDialog() {
      this.dialog = true
    },
    getSpecialDates() {
      this.$api
        .get('categories_site/special_dates')
        .then((res) => {
          this.allCategories[3].categories_all = res.data.data
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.specialDates.loading = false
        })
    },
  },
  beforeUpdate() {
    const Cart = JSON.parse(localStorage.getItem('Cart'))
    if (Array.isArray(Cart)) {
      this.countProducts = String(Cart.length)
    }
  },
  created() {
    this.getCategories()
    this.getSegments()
    this.getSpecialDates()
    if (this.$cookies.get('name')) {
      this.is_logged = true
    }
  },
}
</script>

<style lang="scss" scoped>
::v-deep .u-line-height {
  line-height: 15px;
}
::v-deep .v-treeview-node__label {
  font-size: 14px;
}
::v-deep .v-treeview-node__children {
  display: grid;
  grid-template-columns: repeat(4, 14rem);
  margin-left: 2rem;
  .v-treeview-node--leaf {
    .v-treeview-node__root {
      padding-left: 0;
      padding-right: 0;
      .v-treeview-node__content {
        margin-left: 0;
        .v-treeview-node__label {
          .v-list-item {
            padding: 0;
          }
        }
      }
      .v-treeview-node__level {
        display: none;
      }
    }
  }
}
</style>
